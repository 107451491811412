import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nie znaleziono" />
    <section className="page-404">
      <div>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <Link to="/" className="btn-custom">
          Powrót do strony głównej
        </Link>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
